<div class="" data-content="sidebarMenu" (mouseenter)="mouseOverSidebar()" (mouseleave)="mouseLeaveSidebar()">
  <div class="nk-sidebar-element nk-sidebar-head">
    <div class="nk-menu-trigger">
      <button (click)="onClickToggleActiveSidebar($event)" class="nk-nav-toggle nk-quick-nav-icon d-lg-none"
        data-target="sidebarMenu"><em class="icon ni ni-arrow-left"></em></button>
      <button (click)="onClickToggleCompactSidebar($event)"
        class="nk-nav-compact nk-quick-nav-icon d-none d-lg-inline-flex" data-target="sidebarMenu"><em
          class="icon ni ni-menu"></em></button>
    </div>
    <div class="nk-sidebar-brand">
      <!-- <a [routerLink]="['/home']" class="logo-link nk-sidebar-logo"> -->
      <a class="logo-link nk-sidebar-logo">
        <img width="151" class="logo-light logo-img" src="./assets/img/logo-lockbin.svg" alt="logo">
        <img width="151" class="logo-dark logo-img" src="./assets/img/logo-lockbin.svg" alt="logo-dark">
      </a>
    </div>
  </div><!-- .nk-sidebar-element -->
  <div class="nk-sidebar-bottom-border"></div>
  <div class="nk-sidebar-element nk-sidebar-body">
    <div class="nk-sidebar-content">
      <ngx-simplebar class="nk-sidebar-menu" [options]="options">

        <ul class="nk-menu">
          <li *ngFor="let menuItem of menuItems" [ngClass]="!menuItem.isHeading ? menuItem.cssClasses : ''"
            [class.nk-menu-heading]="menuItem.isHeading"
            [class.nk-menu-item]="!menuItem.isHeading && !menuItem.isSeparator"
            [class.nk-menu-hr]="menuItem.isSeparator && menuItem.childs.length"
            [class.has-sub]="menuItem.childs.length > 0" routerLinkActive="active current-page">

            <ng-container *ngIf="!menuItem.isSeparator">

              <h6 class="overline-title text-primary-alt" [ngClass]="menuItem.isHeading ? menuItem.cssClasses : ''"
                *ngIf="menuItem.isHeading">{{ menuItem.title }}</h6>

              <a [routerLink]="menuItem.route" class="nk-menu-link nk-menu-toggle"
                [class.nk-menu-link-disabled]="menuItem.disabled"
                *ngIf="!menuItem.isHeading && menuItem.childs.length === 0">
                <span class="nk-menu-icon" *ngIf="menuItem.icon"><em class="icon ni"
                    [ngClass]="'ni-' + menuItem.icon"></em></span>
                <svg-icon class="nk-menu-icon" *ngIf="menuItem.iconSVG" src="{{ menuItem.iconSVG }}"></svg-icon>
                <span class="nk-menu-text">{{ menuItem.title }}</span>
              </a>

              <span (click)="onClickFirstLevel($event)" class="nk-menu-link nk-menu-toggle"
                *ngIf="!menuItem.isHeading && menuItem.childs.length > 0">
                <span class="nk-menu-icon" *ngIf="menuItem.icon"><em class="icon ni"
                    [ngClass]="'ni-' + menuItem.icon"></em></span>
                <svg-icon class="nk-menu-icon" *ngIf="menuItem.iconSVG" src="{{ menuItem.iconSVG }}"></svg-icon>
                <span class="nk-menu-text">{{ menuItem.title }}</span>
              </span>

              <ul class="nk-menu-sub" *ngIf="menuItem.childs.length > 0">
                <li class="nk-menu-item" *ngFor="let childItem of menuItem.childs" [ngClass]="childItem.cssClasses"
                  routerLinkActive="active current-page">
                  <a [routerLink]="[menuItem.route + childItem.route]" class="nk-menu-link"
                    [class.nk-menu-link-disabled]="childItem.disabled">
                    <span class="nk-menu-text">{{ childItem.title }}</span>
                  </a>
                </li>
              </ul>

            </ng-container>

          </li>
        </ul>

      </ngx-simplebar>
    </div><!-- .nk-sidebar-content -->
  </div><!-- .nk-sidebar-element -->
  <div class="nk-sidebar-bottom-border"></div>
  <div class="nk-sidebar-element nk-sidebar-footer">
    <ul class="nk-menu">
      <li class="nk-menu-item" ngbDropdown placement="top-start">
        <span class="nk-menu-link pt-0 pb-0" ngbDropdownToggle>
          <span class="nk-menu-icon">
            <svg-icon class="nk-menu-icon" src="assets/img/menu/user.svg"></svg-icon>
          </span>
          <span *ngIf="this.storeService.getIsSavingUserPersistentData()===false" class="nk-menu-text info-text text-primary">
            Usuario
            <span class="d-block fs-6 fw-bold text-uppercase">
              {{ this.userViewDto | userViewDtoPipe }}
            </span>
          </span>
        </span>

        <div class="dropdown-menu dropdown-menu-sidebar dropdown-menu-s1" ngbDropdownMenu>
          <!-- <div class="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
        <div class="user-card">
          <div class="user-avatar">
            <span>JM</span>
          </div>
          <div class="user-info">
            <span class="lead-text">Juan Pérez</span>
            <span class="sub-text">info@ideit.es</span>
          </div>
        </div>
      </div> -->
          <div class="dropdown-inner">
            <ul class="link-list py-1">
              <li><a href="#"><em class="icon ni ni-setting-alt"></em><span>Preferencias</span></a></li>
            </ul>
          </div>
          <div class="dropdown-inner">
            <ul class="link-list py-1">
              <li><a (click)="doLogout()"><em class="icon ni ni-signout"></em><span>Salir</span></a></li>
            </ul>
          </div>
        </div>


      </li>
    </ul>
  </div>
</div>
