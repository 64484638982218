
<form [formGroup]="formGroup" autocomplete="on">
  <div class="nk-block">
    <div class="row gy-4">

      <div class="col-xxl-6 col-md-6">
        <div class="form-group">
            <label class="form-label" for="latitude">Latitude</label>
            <div class="form-control-wrap">
                <input type="number" class="form-control" id="latitude" placeholder="Latitude"
                  formControlName="latitude"
                  [ngClass]="{ 'is-invalid' : formGroup.controls.latitude.errors && formGroup.controls.latitude.touched }">

                  <div class="invalid-feedback" *ngIf="formGroup.controls.latitude.hasError('required')">
                    {{ 'FIELD_REQUIRED' | translate }}
                  </div>
            </div>
        </div>
      </div><!--col-->
      <div class="col-xxl-6 col-md-6">
          <div class="form-group">
              <label class="form-label" for="longitude">Longitude</label>
              <div class="form-control-wrap">
                  <input type="number" class="form-control" id="longitude" placeholder="Longitude"
                    formControlName="longitude"
                    [ngClass]="{ 'is-invalid' : formGroup.controls.longitude.errors && formGroup.controls.longitude.touched }">

                    <div class="invalid-feedback" *ngIf="formGroup.controls.longitude.hasError('required')">
                      {{ 'FIELD_REQUIRED' | translate }}
                    </div>
              </div>
          </div>
      </div><!--col-->
    </div>
  </div>

  <div class="nk-block">
    <div class="row gy-2">
      <div class="col-xxl-6 col-md-6">
        <div class="form-group">
          <button (click)="onClickSave()" class="btn btn-primary">
            {{ 'BTN_SAVE' | translate }}
          </button>
        </div>
      </div><!--col-->
    </div><!--row-->
  </div><!--block-->

</form>

