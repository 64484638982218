// Angular modules
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';

// External modules
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from '@services/store.service';
import { NGXLogger } from 'ngx-logger';

// Helpers

// Models
import { Attachment, IUploadService } from '@models/index';

// Modal
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Base class
import { EventBusService } from '@services/event-bus.service';
import { finalize, Subject, Subscription, takeUntil } from 'rxjs';
import { HttpEvent, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'lockbin-upload-attachment',
  templateUrl: './upload-attachment.component.html',
  styles: [
  ]
})
export default class UploadAttachmentComponent implements OnInit {

  @Input() data: any | undefined;
  @Output() submitData: EventEmitter<any> = new EventEmitter();
  @Output() submitClose: EventEmitter<null> = new EventEmitter();

  @ViewChild("fileUpload") fileUpload!: ElementRef;

  isDragging = false;
  selectedFile!: File | undefined;
  isUploading = false;

  uploadProgress: number = 0;
  uploadSubscription!: Subscription;
  uploadService: IUploadService | undefined;

  hasError = false;

  isUploaded = false;
  attachmentFile!: Attachment | null;

  // -------------------------------------------------------------------------------
  // NOTE Constructor & Init -------------------------------------------------------
  // -------------------------------------------------------------------------------
  constructor(
    private location: Location,
    private titleService: Title,
    private storeService: StoreService,
    private logger: NGXLogger,
    private translateService: TranslateService,
    private activeModal: NgbActiveModal,
    private eventBusService: EventBusService,

  ) {

  }

  ngOnInit(): void {

    if (!this.data) return;

    // En el data llega un atributo que tiene el Servicio que hay que llamar para hacer upload.
    this.uploadService = this.data.service;

  }

  public onClickClose(): void {
    this.submitClose.emit();
  }

  public onClickSubmit() {
    this.submitData.emit(this.attachmentFile);
  }

  public onUploadFile() {

    if (this.data && this.selectedFile && this.uploadService) {

      const upload$ = this.uploadService.uploadDocument(this.data.entity.id, this.selectedFile);

      this.isUploading = true;

      this.uploadSubscription = upload$.pipe(
        finalize(() => this.resetUpload())
      ).subscribe({
        next: (event: HttpEvent<Attachment>) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              if (event.total) {
                this.uploadProgress = Math.round(100 * (event.loaded / event.total));
              }
              break;
            case HttpEventType.Response:
              console.log('Respuesta del servidor:', event.body);
              this.isUploaded = true;
              this.attachmentFile = event.body;
              break;
          }

        },
        error: (err) => {
          console.error('Error al subir el documento:', err);
          this.isUploading = false;
          this.hasError = true;
        }
      });

    }

  }

  public onCancelUpload() {

    this.uploadSubscription.unsubscribe();

    this.resetUpload();
  }

  public onDrag(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.isDragging = true;
  }

  public onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.isDragging = false;

    if (event.dataTransfer && event.dataTransfer.files) {
      const file = event.dataTransfer.files.item(0);
      if (file) {
        this.doSelectFile(file);
      }
    }
  }

  public onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.isDragging = false;
  }

  public onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.isDragging = true;
  }

  public onDragEnter(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.isDragging = true;
  }

  public onFileSelected(event: Event) {
    const file = (event.target as HTMLInputElement)?.files?.[0];
    if (file) {
      this.doSelectFile(file);
    }
  }

  public onFileDelete() {

    this.fileUpload.nativeElement.value = '';
    this.selectedFile = undefined;
    this.hasError = false;
  }

  private doSelectFile(file: File) {

    if (file) {
      this.selectedFile = file;
    }
  }

  private resetUpload() {

    this.isUploading = false;

    this.uploadProgress = 0;

  }

}
