import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ICoords } from '@models/index';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import Swal from 'sweetalert2';

@Component({
  selector: 'lockbin-change-lat-lng',
  templateUrl: './change-lat-lng.component.html',
  styles: [],
})
export default class ChangeLatLngComponent implements OnInit {
  @Input() data: ICoords | undefined;
  @Output() submitData: EventEmitter<ICoords> = new EventEmitter();
  @Output() submitClose: EventEmitter<null> = new EventEmitter();

  formGroup!: FormGroup<{
    latitude: FormControl<number | null>;
    longitude: FormControl<number | null>;
  }>;

  constructor(private logger: NGXLogger, private translateService: TranslateService) {}

  ngOnInit(): void {
    if (!this.data) return;

    this.formGroup = new FormGroup({
      latitude: new FormControl<number>(
        { value: this.data.latitude, disabled: false },
        {
          validators: [Validators.required],
          nonNullable: false,
          updateOn: 'change',
        }
      ),
      longitude: new FormControl<number>(
        { value: this.data.longitude, disabled: false },
        {
          validators: [Validators.required],
          nonNullable: false,
          updateOn: 'change',
        }
      ),
    });

  }

  onClickSave() {
    Swal.fire({
      title: 'Se van a modificar las coordenadas del equipo',
      text: '¿Que desea hacer?',
      showClass: { popup: 'animated fadeInDown ' },
      // icon: 'question',
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('BTN_CONTINUE'),
      cancelButtonText: this.translateService.instant('BTN_CANCEL'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.submitData.emit(this.formGroup.value as ICoords);
      }
    });
  }
}
