// Angular modules
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

// External modules
import { Observable } from 'rxjs';

// Environment
import { environment } from '@env/environment';

// Helpers
import { StorageHelper } from '@helpers/storage.helper';

// Enums
import { StorageKey } from '@enums/storage-key.enum';

// Models
import { UserAuthApiDto, UserViewDto } from '@security/models';

// Services
import { StoreService } from '@services/store.service';
import { PREDEFINED_ROLES } from '@security/enums/role.enum';


const AUTH_API = environment.apiBaseUrlSecurity;

const httpOptions = {
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private storeService: StoreService) { }

  // get httpParams() {
  //   return new HttpParams()//.set('fields', 'name,capital,alpha2Code,flags.png,population');
  // }

  get roles() {
    return PREDEFINED_ROLES;
  }

  signin( credentials: any ): Observable<UserAuthApiDto> {

    this.removeStorages();

    const url = `${AUTH_API}/signin`;

    return this.http.post<UserAuthApiDto>(url, credentials, httpOptions);
  }

  signout( ) {

    this.removeStorages();

    const url = `${AUTH_API}/signout`;

    return this.http.post(url, httpOptions);
  }

  refreshToken() {

    const url = `${AUTH_API}/refreshtoken`;

    return this.http.post(url, httpOptions);
  }

  whoami(): Observable<UserAuthApiDto> {

    const url = `${AUTH_API}/whoami`;

    const queryResults$ = this.http.get<UserAuthApiDto>(url);

    return queryResults$;
  }

  hello() {
    const url = `${AUTH_API}/hello`;
    return this.http.get(url, httpOptions);
  }

  isLogged() {
    // TODO: Cambiar esto!!!
    return true;
  }

  getRole() {
    return StorageHelper.getItem(StorageKey.ROLE);
  }

  private removeStorages() {

    Object.keys(StorageHelper)
      .forEach(key => StorageHelper.removeItem(key))

    StorageHelper.removeItem(StorageKey.ROLE);
    StorageHelper.removeItem(StorageKey.USER);
    StorageHelper.removeItem(StorageKey.USER_ID);
    StorageHelper.removeItem(StorageKey.USER_PREFERENCES);

    this.storeService.setUser(new UserViewDto());

  }

}
