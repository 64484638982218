<div class="container-fluid">
    <div class="nk-footer-wrap">
        <div class="nk-footer-copyright"> &copy; {{ year }} LockBin. <small class="text-gray">v{{ version }}</small></div>
        <!-- <div class="legal-logos" *ngIf="showFeder">
          <img width="324" height="24" src="./assets/img/legal-feder-text.png" alt="">
          <img width="410" height="38" src="./assets/img/legal-feder-logos.png" alt="">
        </div> -->
        <div lockbin-language-selector [showName]="true"></div>
        <!--<div class="nk-footer-links">
            <ul class="nav nav-sm">
                <li class="nav-item dropup" ngbDropdown placement="top-end">
                    <div class="dropdown-toggle dropdown-indicator has-indicator nav-link text-base" ngbDropdownToggle data-bs-toggle="dropdown" data-offset="0,10"><span>Español</span></div>
                    <div class="dropdown-menu dropdown-menu-sm dropdown-menu-end" ngbDropdownMenu>
                        <ul class="language-list">
                            <li>
                                <a href="#" class="language-item">
                                    <span class="language-name">English</span>
                                </a>
                            </li>
                            <li>
                              <a href="#" class="language-item">
                                  <span class="language-name">Portugués</span>
                              </a>
                          </li>
                          <li>
                                <a href="#" class="language-item">
                                    <span class="language-name">Français</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="nav-item">
                    <a href="#" class="nav-link" (click)="openModal($event, modalContent)"><em class="icon ni ni-globe"></em><span class="ms-1">Seleccionar algo</span></a>
                </li>
            </ul>
        </div>-->
    </div>
</div>

<!-- NOTE Content -->
<ng-content></ng-content>

<ng-template #modalContent let-modalContent>
  <div class="modal-content">
      <button class="close" (click)="modalContent.dismiss()" data-bs-dismiss="modal"><em class="icon ni ni-cross-sm"></em></button>
      <div class="modal-body modal-body-md">
          <h5 class="title mb-4">Select Your Country</h5>
          <div class="nk-country-region">
              <ul class="country-list text-center gy-2">
                  <li>
                      <a href="#" class="country-item">
                          <img src="./images/flags/arg.png" alt="" class="country-flag">
                          <span class="country-name">Argentina</span>
                      </a>
                  </li>
                  <li>
                      <a href="#" class="country-item">
                          <img src="./images/flags/aus.png" alt="" class="country-flag">
                          <span class="country-name">Australia</span>
                      </a>
                  </li>
                  <li>
                      <a href="#" class="country-item">
                          <img src="./images/flags/bangladesh.png" alt="" class="country-flag">
                          <span class="country-name">Bangladesh</span>
                      </a>
                  </li>
                  <li>
                      <a href="#" class="country-item">
                          <img src="./images/flags/canada.png" alt="" class="country-flag">
                          <span class="country-name">Canada <small>(English)</small></span>
                      </a>
                  </li>
                  <li>
                      <a href="#" class="country-item">
                          <img src="./images/flags/china.png" alt="" class="country-flag">
                          <span class="country-name">Centrafricaine</span>
                      </a>
                  </li>
                  <li>
                      <a href="#" class="country-item">
                          <img src="./images/flags/china.png" alt="" class="country-flag">
                          <span class="country-name">China</span>
                      </a>
                  </li>
                  <li>
                      <a href="#" class="country-item">
                          <img src="./images/flags/french.png" alt="" class="country-flag">
                          <span class="country-name">France</span>
                      </a>
                  </li>
                  <li>
                      <a href="#" class="country-item">
                          <img src="./images/flags/germany.png" alt="" class="country-flag">
                          <span class="country-name">Germany</span>
                      </a>
                  </li>
                  <li>
                      <a href="#" class="country-item">
                          <img src="./images/flags/iran.png" alt="" class="country-flag">
                          <span class="country-name">Iran</span>
                      </a>
                  </li>
                  <li>
                      <a href="#" class="country-item">
                          <img src="./images/flags/italy.png" alt="" class="country-flag">
                          <span class="country-name">Italy</span>
                      </a>
                  </li>
                  <li>
                      <a href="#" class="country-item">
                          <img src="./images/flags/mexico.png" alt="" class="country-flag">
                          <span class="country-name">México</span>
                      </a>
                  </li>
                  <li>
                      <a href="#" class="country-item">
                          <img src="./images/flags/philipine.png" alt="" class="country-flag">
                          <span class="country-name">Philippines</span>
                      </a>
                  </li>
                  <li>
                      <a href="#" class="country-item">
                          <img src="./images/flags/portugal.png" alt="" class="country-flag">
                          <span class="country-name">Portugal</span>
                      </a>
                  </li>
                  <li>
                      <a href="#" class="country-item">
                          <img src="./images/flags/s-africa.png" alt="" class="country-flag">
                          <span class="country-name">South Africa</span>
                      </a>
                  </li>
                  <li>
                      <a href="#" class="country-item">
                          <img src="./images/flags/spanish.png" alt="" class="country-flag">
                          <span class="country-name">Spain</span>
                      </a>
                  </li>
                  <li>
                      <a href="#" class="country-item">
                          <img src="./images/flags/switzerland.png" alt="" class="country-flag">
                          <span class="country-name">Switzerland</span>
                      </a>
                  </li>
                  <li>
                      <a href="#" class="country-item">
                          <img src="./images/flags/uk.png" alt="" class="country-flag">
                          <span class="country-name">United Kingdom</span>
                      </a>
                  </li>
                  <li>
                      <a href="#" class="country-item">
                          <img src="./images/flags/english.png" alt="" class="country-flag">
                          <span class="country-name">United State</span>
                      </a>
                  </li>
              </ul>
          </div>
      </div>
  </div>
</ng-template>
