<div class="dropdown language-dropdown me-1" ngbDropdown *ngIf="languages && currentLanguage && languages.length > 1">
  <div class="opacity-50" ngbDropdownToggle *ngIf="showName">
    <span class="fs-12px pe-1" >{{ currentLanguage.name }}</span>
    <button class="dropdown-toggle btn btn-icon p-0 nk-quick-nav-icon" aria-expanded="false" [title]="currentLanguage.name">
      <div class="quick-icon border border-light"><img class="icon" src="./assets/img/flags/{{ currentLanguage.flag }}" alt=""></div>
    </button>
  </div>
  <button *ngIf="!showName" class="dropdown-toggle btn btn-icon p-0 nk-quick-nav-icon" aria-expanded="false" [title]="currentLanguage.name" ngbDropdownToggle>
    <div class="quick-icon border border-light"><img class="icon" src="./assets/img/flags/{{ currentLanguage.flag }}" alt=""></div>
  </button>
  <div class="dropdown-menu dropdown-menu-end dropdown-menu-s1" ngbDropdownMenu>
    <ul class="language-list">
      <li *ngFor="let lang of languages"><button (click)="onClickChangeLanguage(lang)" class="btn language-item w-100 fw-normal" ngbDropdownItem><img src="./assets/img/flags/{{ lang.flag }}" alt="" class="language-flag"><span class="language-name">{{ lang.name }}</span></button></li>
    </ul>
  </div>
</div>
