
<div class="nk-body bg-lighter-old npc-general has-sidebar" [class.is-sidebar-compact]="isSidebarCompact">
  <div class="nk-app-root">
    <div class="nk-main">

      <lockbin-layout-sidebar class="nk-sidebar nk-sidebar-fixed is-theme"></lockbin-layout-sidebar>

      <div class="nk-wrap">

        <lockbin-layout-header-background></lockbin-layout-header-background>

        <!-- <lockbin-layout-header class="nk-header nk-header-fixed is-theme"></lockbin-layout-header> -->

        <div class="nk-content">
          <ng-content></ng-content>
        </div>

        <lockbin-layout-footer class="nk-footer"></lockbin-layout-footer>

      </div>

    </div>
  </div>
</div>
