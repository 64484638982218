
<form autocomplete="off">

  <div class="nk-block">

    <div class="upload-zone small bg-lighter dropzone dz-clickable"
      [class.dz-drag-hover]="isDragging"
      (dragover)="onDragOver($event)"
      (dragenter)="onDragEnter($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
      (drag)="onDrag($event)"
      (click)="fileUpload.click()"
      *ngIf="!selectedFile">
      <div class="dz-message" data-dz-message="">
        <span class="dz-message-text"><span>Arrastra</span> un archivo aquí o <span>examina los ficheros</span>.</span>
      </div>
    </div>

    <input #fileUpload type="file" class="file-input d-none" (change)="onFileSelected($event)">

    <div class="nk-upload-list" *ngIf="selectedFile">
      <h6 class="title" *ngIf="!isUploaded">Documento seleccionado</h6>
      <h6 class="title" *ngIf="isUploaded">Documento enviado al servidor</h6>
      <div class="nk-upload-item">
        <div class="nk-upload-icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72"><g><path d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z" style="fill:#599def"></path><path d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z" style="fill:#c2e1ff"></path><rect x="27" y="31" width="18" height="2" rx="1" ry="1" style="fill:#fff"></rect><rect x="27" y="36" width="18" height="2" rx="1" ry="1" style="fill:#fff"></rect><rect x="27" y="41" width="18" height="2" rx="1" ry="1" style="fill:#fff"></rect><rect x="27" y="46" width="12" height="2" rx="1" ry="1" style="fill:#fff"></rect></g></svg>
        </div>
        <div class="nk-upload-info">
          <div class="nk-upload-title">
            <span class="title">{{ selectedFile.name }}</span>
            <span class="meta" *ngIf="isUploading">{{ uploadProgress }}% hecho</span>
          </div>
          <div class="nk-upload-size" *ngIf="!isUploading">{{ selectedFile.size| fileSize }}</div>
          <div class="nk-upload-progress" *ngIf="isUploading">
            <div class="progress progress-sm">
              <div class="progress-bar" [style.width]="uploadProgress + '%'"></div>
            </div>
          </div>
        </div>
        <div class="nk-upload-action" *ngIf="!isUploaded">
          <button (click)="onFileDelete()" class="btn btn-icon btn-trigger" *ngIf="!isUploading"><em class="icon ni ni-trash"></em></button>
          <button (click)="onCancelUpload()" class="btn btn-icon btn-trigger" *ngIf="isUploading"><em class="icon ni ni-cross"></em></button>
        </div>
        <div class="nk-upload-action" *ngIf="isUploaded">
          <em class="icon ni ni-check-circle text-success h2"></em>
        </div>
      </div>
    </div>
    <div class="text-danger text-center py-1 fs-13px" *ngIf="hasError">Ha ocurrido un error al cargar el documento.</div>
  </div>

  <div class="nk-block" *ngIf="!isUploaded">
    <div class="row gy-2">
      <div class="col-xxl-6 col-md-6">
        <div class="form-group">
          <button (click)="onUploadFile()" class="btn btn-primary" [disabled]="!selectedFile || isUploading">
            {{ 'BTN_UPLOAD' | translate }}
          </button>
        </div>
      </div><!--col-->
      <div class="col-xxl-6 col-md-6 text-end">
        <div class="form-group">
          <button (click)="onClickClose()" class="btn btn-link" [disabled]="isUploading">
            {{ 'BTN_CANCEL' | translate }}
          </button>
        </div>
      </div><!--col-->
    </div><!--row-->
  </div><!--block-->

  <div class="nk-block" *ngIf="isUploaded">
    <div class="row gy-2">
      <div class="col-xxl-6 col-md-6">
        <div class="form-group">
          <button (click)="onClickSubmit()" class="btn btn-primary">
            {{ 'BTN_CLOSE' | translate }}
          </button>
        </div>
      </div><!--col-->
      <div class="col-xxl-6 col-md-6 text-end">
      </div><!--col-->
    </div><!--row-->
  </div><!--block-->

</form><!--form-->
