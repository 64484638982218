// Angular modules
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OnInit } from '@angular/core';
import { StorageKey } from '@enums/storage-key.enum';
import { environment } from '@env/environment';
import { StorageHelper } from '@helpers/storage.helper';
import { IUserInterfacePrefs } from '@models/index';
import { ILanguage } from '@models/language.interface';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lockbin-language-selector, [lockbin-language-selector]',
  templateUrl: './language-selector.component.html',
  styleUrls: [],
})
export class LanguageSelectorComponent implements OnInit {

  @Input() showName = false;

  @Output() languageChange: EventEmitter<ILanguage> = new EventEmitter();

  languages!: ILanguage[];
  currentLanguage!: ILanguage;

  constructor(private translateService: TranslateService) {
    this.languages = environment.languages;
    const currentLanguage = this.languages.find((lang) => lang.code === this.translateService.currentLang);
    if (currentLanguage) {
      this.currentLanguage = currentLanguage;
    }
  }

  public ngOnInit(): void {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.renderCurrentLanguage(event.lang);
      this.saveToInterfacePreferences(event.lang);
    });
  }

  public onClickChangeLanguage(language: ILanguage) {
    this.translateService.use(language.code);
  }

  private renderCurrentLanguage(langCode: string) {
    const languageItem = this.languages.find((lang) => lang.code === langCode);
    if (languageItem) {
      this.currentLanguage = languageItem;
      this.languageChange.emit(languageItem);
    }
  }

  // Si no hay preferencias del usuario no se guarda
  private saveToInterfacePreferences(langCode: string) {
    const userPreferences: IUserInterfacePrefs = StorageHelper.getItem(StorageKey.USER_INTERFACE_PREFS);
    if (userPreferences) {
      userPreferences.language = langCode;
      StorageHelper.setItem(StorageKey.USER_INTERFACE_PREFS, userPreferences);
    }
  }
}
